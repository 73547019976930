.check-wrapper {
    background-color: #ddd;
    color: #000;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
}
.check {
    width: 8.5in;
    height: 3.25in;
    margin: auto;
    padding: .125in;
    background-color: #fff;
    text-align: left;
}
.outer,
.check {
    position: relative;
    border: none;
    border-collapse: collapse;
    padding: .125in;
}
div { 
    border: solid #ddd 0px;
    border-collapse: collapse;
}
.top-row {
    position: relative;
    height: 1in
}
.middle-row {
    position: relative;
    height: 1in
}
.third-row {
    position: relative;
    height: .75in
}
.from-block {
    position: absolute;
    left: 0in;
    top: 0in;
    width: 2.5in;
}
.bank-logo {
    position: absolute;
    left: 2.6in;
    top: -5px;
    width: 1.4in;
    text-align: right;
}
.bank-block {
    position: absolute;
    left: 4in;
    top: 0;
}
.check-number {
    position: absolute;
    left: 7in;
    top: 0;
    width: 1.25in;
    text-align: right;
}
.aba-fraction {
    position: absolute;
    left: 7in;
    top: .25in;
    font-size: 75%;
}
.check-date {
    position: absolute;
    left: 6.5in;
    top: .65in;
}
.pay-to-label {
    position: absolute;
    left: 0;
    top: 0;
}
.payee-name {
    position: absolute;
    left: .75in;
    top: 0;
}
.dollar-sign-label {
    position: absolute;
    left: 6.5in;
    top: 0in;
}
.amount-numeric {
    position: absolute;
    left: 6.65in;
    top: 0in;
}
.amount-written {
    position: absolute;
    left: 0;
    top: .5in;
}
.dollars-label {
    position: absolute;
    left: 6.65in;
    top: .5in;
}
.memo-label {
    position: absolute;
    left: 0;
    top: .4in;
}
.memo-block {
    position: absolute;
    left: .45in;
    top: .4in;
}
.signature-block {
    position: absolute;
    left: 5in;
    top: .4in;
}
.signature-label {
    position: absolute;
    left: 6.25in;
    top: .6in;
    font-size: 75%;
    font-weight: bold;
}
.micr-area {
    position: absolute;
    top: 3.01in;
    left: 1in;
    border-top: solid 0px #bbb;
    font-family: "MICR";
    font-size: 25.6px; /* 19.2pt */
}
@font-face {
    font-family: "MICR";
    src: url('./fonts/MICR.ttf') format('truetype');
}
